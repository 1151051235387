<template>
  <div>
    <div id="pageHistory" class="mainContents">
      <UserNavSp :page="'history'" />

      <UserTitle :user="user" />

      <UserNav :user="user" :page="'history'" />

      <div v-if="isOwner" class="editButton">
        <p>
          <router-link :to="{ name: 'history-edit' }">
            自分史を編集する
          </router-link>
        </p>
      </div>

      <template v-if="history.display">
        <div class="historyArea">
          <div class="inner">
            <LikeButtonP
              ref="likeButton"
              :object="history"
              object-type="HISTORY"
            />

            <div class="editorInner" v-html="history.description_stripped" />
            <p class="update">
              {{ history.date }}
            </p>
          </div>
          <!-- /.historyArea -->
        </div>

        <div class="snsArea">
          <div class="inner">
            <LikeButtonP
              ref="likeButton"
              :object="history"
              object-type="HISTORY"
            />
            <SnsButtons :text="pageTitle" :url="pageUrl" />
          </div>
          <!-- /.snsArea -->
        </div>

        <div v-if="history.allow_comments" class="messageArea">
          <div class="inner">
            <template v-if="!loggedIn">
              コメントするにはログインしてください
            </template>
            <template v-if="loggedIn">
              <h2>
                この記事にコメントする
              </h2>
              <form>
                <div class="textareaFrame">
                  <textarea v-model="comment" placeholder="コメント入力…" />
                </div>
                <p class="button">
                  <button
                    type="submit"
                    :disabled="formInvalid"
                    @click="saveComment($event)"
                  >
                    送信
                  </button>
                </p>
              </form>
            </template>
            <ul class="messageList">
              <BaseCommentCard
                v-for="c in historyComment.comments"
                :key="c.id"
                :comment="c.node"
                :type="'history'"
                :owner="history.user"
              />
            </ul>
          </div>
          <div v-if="historyComment.pageInfo.hasNextPage" class="buttonArea">
            <p>
              <button type="submit" @click="fetchMoreComments($event)">
                もっと見る
              </button>
            </p>
            <!-- /.buttonArea -->
          </div>
          <!-- /.messageArea -->
        </div>
      </template>
      <template v-else>
        <div class="nwArea">
          <p>現在、制作中</p>
        </div>
      </template>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import { authComputed } from '@/store/helpers.js'
import { mapState } from 'vuex'
import LikeButtonP from '@/components/LikeButtonP.vue'
import SnsButtons from '@/components/SnsButtons.vue'

export default {
  el: '#pageHistory',
  components: {
    UserTitle,
    UserNav,
    UserNavSp,
    LikeButtonP,
    SnsButtons
  },
  props: {
    u: {
      type: Object,
      required: true
    },
    history: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {},
      isUploading: false,
      comment: '',
      likes: []
    }
  },
  computed: {
    ...authComputed,
    ...mapState(['historyComment']),
    isOwner: function () {
      return this.$store.getters.isOwner(this.$route.params.id)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.comment) {
        return true
      }

      if (this.comment.trim() === '') {
        return true
      }

      return false
    },
    liked: function () {
      if (this.loggedIn) {
        return this.history.has_liked
      }
      return this.likes.includes(this.history.id)
    },
    pageTitle: function () {
      return '自分史'
    },
    pageUrl: function () {
      return window.location.href
    }
  },
  created: function () {
    this.user = this.u

    if (!this.history.description) {
      this.history.description = {}
    }
  },
  beforeDestroy () {},
  mounted () {
    this.fetchComments()
    if (localStorage.getItem('noLoginHistoryLikes')) {
      try {
        this.likes = JSON.parse(localStorage.getItem('noLoginHistoryLikes'))
      } catch (e) {
        localStorage.removeItem('noLoginHistoryLikes')
      }
    }
  },
  methods: {
    saveComment (event) {
      event.preventDefault()
      this.isUploading = true
      this.$store
        .dispatch('historyComment/postComment', {
          id: this.history.id,
          comment: this.comment
        })
        .then(() => {
          this.comment = ''
          this.isUploading = false
        })
        .catch(() => {
          this.isUploading = false
        })
    },
    fetchComments () {
      if (!this.history.id) {
        return
      }
      this.$store
        .dispatch('historyComment/fetchComments', {
          id: this.history.id,
          first: 12,
          add: false
        })
        .then(() => {})
    },
    fetchMoreComments (event) {
      if (!this.history.id) {
        return
      }
      this.$store
        .dispatch('historyComment/fetchComments', {
          id: this.history.id,
          first: 12,
          after: this.historyComment.pageInfo.endCursor,
          add: true
        })
        .then(() => {})
    }
  }
}
</script>

<style scoped></style>
